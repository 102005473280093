.cancelButton {
    width: 100%; 
    background-color: #eec8d2; 
    border: 0;
    transition: background-color 0.3s ease;
}

.cancelButton:hover {
    background-color: #ecbdca; 
}

.acceptButton {   
    width: 100%; 
    background-color: #b4d49d; 
    border: 0;
    transition: background-color 0.3s ease;

}

.acceptButton:hover {
    background-color: #a5cf87; 
}