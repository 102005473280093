.card-address {
    display: flex;
    justify-content: space-between;
    min-height: 120px;
    background-color: var(--gray-50);
    margin-bottom: 16px;
    border-radius: 5px;
    border: 2px solid var(--gray-200);
    transition: background-color 0.3s ease;
    font-size: .9em;
}

.card-address:hover {
    background-color: var(--gray-100);
    cursor: pointer;
}

.card-address:active {
    background-color: var(--gray-50);
    cursor: pointer;
}

.delete-card {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--gray-200);
    border-radius: 5px;
    padding: 6px;
    transition: transform 0.3s ease;
}

.delete-card:hover { 
    transform: scale(1.1);
}