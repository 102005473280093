.p-datascroller-header {
    background-color: var(--gray-50);
}

.p-datascroller-content {
  scrollbar-width: none; /* Para Firefox */
  -ms-overflow-style: none; 
  background-color: var(--gray-50);
  width: 100%;
  padding: 0;
  border-radius: 5px;
}

li {
    border-width: 0;
    border-radius: 5;
}

.p-datascroller-header {
    color: var(--gray-900);
    font-family: sans-serif;
    font-size: 1.5em;
    display: flex;
    justify-content: flex-start;
    padding: 0;
    font-weight: 500;
}

#buttomMp {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    padding: 8px 30% 8px 30%;
}

.mp-checkout-bricks__payment-form {
    padding: 0 16px 4px 16px !important;
    /* background-color: var(--gray-50) !important; */
}

@media only screen and (max-width: 768px) {
    .listItemCardContainer {
        justify-content: center !important;
        align-items: center !important;
    }
}

@media only screen and (max-width: 480px) {
    .listItemCardContainer {
        justify-content: center !important;
        align-items: center !important;
    }
}
