.container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--gray-800);
    font-size: 1em;
    min-height: 400px;
}

.resumenContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px;
}

.circle-check {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 100px;
    background-color: #b4d49d;
    margin-bottom: 16px;
}

.icon-check {
    font-size: 2em;
    color: var(--gray-800);
}

.gratitude {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 24px;
}