@media only screen and (max-width: 768px) {
    .p-menuitem-content {
        padding: 0
    }
    .p-menuitem-link {
       width: 100%;
       display: flex;
       justify-content: flex-start;
       align-items: center;
    }
    .p-inputgroup {
        align-self: center;
        max-width: 146px;
        max-height: 32px;
    }
    .collectionButton {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .arrowCollection {
        flex: 0.2;
        padding-right: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .redirectToCollection {
        flex: 0.8;
    }
}

/* Media query para pantallas aún más pequeñas (móviles) */
@media only screen and (max-width: 480px) {
    .p-menuitem-content {
        padding: 0
    }
    .p-menuitem-link {
       width: 100%;
       display: flex;
       justify-content: flex-start;
       align-items: center;
    }
    .p-inputgroup {
        padding-left: 8px;
        align-self: center;
        max-width: 146px;
        max-height: 32px;
    }
    .collectionButton {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .arrowCollection {
        flex: 0.2;
        padding-right: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .redirectToCollection {
        flex: 0.8;
    }
    .search {
        width: 28px;
    }
}

@media only screen and (max-width: 8000px) {
    .p-menuitem-content {
        padding: 0
    }
    .p-menuitem-link {
       width: 100%;
       display: flex;
       justify-content: flex-start;
       align-items: center;
    }
    .collectionButton {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .arrowCollection {
        flex: 0.2;
        padding-right: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .redirectToCollection {
        flex: 0.8;
    }
    .p-inputgroup {
        align-self: center;
        max-height: 40px;
    }
}