body {
  background-color: var(--gray-50);
  margin: 0;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: content-box;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

p {
  margin: 0;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

.icon {
  display: inline-block;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.icon:hover {
  cursor: pointer;
  transform: translateY(-5px); /* Eleva el ícono 5px hacia arriba */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Añade una sombra para dar el efecto de elevación */
}

a:link,
a:visited {
  color: inherit; /* Mantiene el color del enlace como el original, o puedes especificar un color */
  text-decoration: none; /* Elimina subrayado, si lo hay */
}

.search:hover {
  cursor: pointer;
  background-color: var(--gray-900) !important;
}
.search:active {
  cursor: pointer;
  background-color: var(--gray-700) !important;
}

.hidden-scroll {
  overflow-x: scroll; /* Habilita el desplazamiento horizontal */
  scrollbar-width: none; /* Firefox: Oculta la barra de desplazamiento */
}

.hidden-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari y Opera: Oculta la barra de desplazamiento */
}

.p-galleria-thumbnail-container {
  background-color: transparent !important;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev, .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next{
  background-color: var(--gray-700) !important;
}

.pi-minus, .pi-plus {
  font-size: 0.6rem;
}

.scrollContainer::-webkit-scrollbar {
  display: none; /* Para Chrome, Safari y Opera */
}

.scrollContainer {
  scrollbar-width: none; /* Para Firefox */
  -ms-overflow-style: none; 
}

/* Media query para pantallas aún más pequeñas (móviles) */
@media only screen and (max-width: 480px) {
  .p-toast .p-toast-message {
    width: 300px;
    position: absolute;
    right: 0;
    font-size: small;
  }
}
