.input-div-container {
    padding-top: 8px;
}

.float-label {
    width: 100%;
    font-size: .9em;
    display: flex;
}

.p-inputtext.p-inputtext-sm {
    background-color: var(--gray-100);
    color: var(--gray-800);
    border-color: var(--gray-500);
}

.p-inputtext.p-invalid.p-component {
    border: 2px solid #f3af42;
}