@media only screen and (max-width: 968px) {
    .container {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .containerDataScroller {
        padding: 0px !important;
    }
    .buyDetail {
        padding-top: 16px !important;
        justify-content: flex-start !important;
    }
    .shoppingCartTitle {
        padding-left: 8px !important;
    }
}
@media only screen and (max-width: 768px) {
    .container {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .containerDataScroller {
        padding: 0px !important;
    }
    .buyDetail {
        padding-top: 16px !important;
        justify-content: flex-start !important;
    }
    .shoppingCartTitle {
        padding-left: 8px !important;
    }
}

@media only screen and (max-width: 480px) {
    .container {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .containerDataScroller {
        padding: 0px !important;
    }
    .buyDetail {
        padding-top: 16px !important;
        justify-content: flex-start !important;
    }
    .shoppingCartTitle {
        padding-left: 8px !important;
    }
}


@media only screen and (max-width: 360px) {
    .container {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .containerDataScroller {
        padding: 0px !important;
        font-size: .6em;
    }
    .buyDetail {
        padding-top: 16px !important;
        justify-content: flex-start !important;
    }
    .shoppingCartTitle {
        padding-left: 8px !important;
    }
}
