@media only screen and (max-width: 768px) {
    .button {
        height: 48px !important;
        width: 24px !important;
    }
}

/* Media query para pantallas aún más pequeñas (móviles) */
@media only screen and (max-width: 480px) {
    .button {
        height: 48px !important;
        width: 24px !important;
    }
}
