@media only screen and (max-width: 768px) {
    .containerView {
        justify-content: center;
        align-items: center;
        padding: 12px
    }
    .containerGalleryAndDescription{
        flex-direction: column;
    }
    .descriptionContainer {
        padding: 8px 16px 0 16px !important;
    }
    .controllerQuantity {
        width: 100% !important;
        justify-content: center !important;
    }
    .controllerQuantityPlus {
        margin-left: 16px;
    }
    .controllerQuantityMinus {
        margin-right: 16px;
    }
}

/* Media query para pantallas aún más pequeñas (móviles) */
@media only screen and (max-width: 480px) {
    .containerView {
        justify-content: center;
        align-items: center;
        padding: 12px
    }
    .containerGalleryAndDescription{
        flex-direction: column;
    }
    .descriptionContainer {
        padding: 8px 16px 0 16px !important;
    }
    .controllerQuantity {
        width: 100% !important;
        justify-content: center !important;
    }
    .controllerQuantityPlus {
        margin-left: 16px;
    }
    .controllerQuantityMinus {
        margin-right: 16px;
    }
}

