.newDirection {
    transition: background-color 0.3s ease;
}
.newDirection:hover {
    background-color: var(--gray-100) !important;
}
.newDirection:active {
    background-color: var(--gray-200) !important;
}

.addressContainer {
    scrollbar-width: none;
    -ms-overflow-style: none; 
}

.addressContainer::-webkit-scrollbar {
    display: none;
}

.container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}