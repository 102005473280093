.p-accordion-content {
    background-color: var(--gray-50);
    color: var(--gray-800);
}

.p-accordion-header-link {
    background-color: var(--gray-200);
}

.p-accordion-header {
    border-bottom: 1px solid var(--gray-500);
}

.p-accordion-header-link {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.last-item {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.p-accordion-tab-active > .last-item{
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.p-accordion .p-accordion-header .p-accordion-header-link, 
.p-accordion-content{
   border-width: 0px;
}

.p-float-label > .p-inputtext {
    background-color: var(--gray-100);
    color: var(--gray-800);
    border-color: var(--gray-600);
}

.p-inputtext:enabled:focus + label {
    left: 0;
    font-size: .8em;
}

.p-inputtext > label {
    border-color: var(--gray-600);
}

.p-float-label > label {
 color: var(--gray-800);
 width: 100%;
 display: flex;
 justify-content: flex-start;
 font-size: .95em;
}

.p-radiobutton.p-highlight .p-radiobutton-box {
    border-color: var(--gray-600);
    background-color: var(--gray-400);
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    background-color: var(--gray-100);
}

.p-radiobutton .p-radiobutton-box {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-400);
}

.p-accordion-content {
    /* min-height: 200px; */
}

.p-accordion-header-link:hover {
    background-color: var(--gray-200);
}

.addressSelectionContainer {
    padding-bottom: 16px; 
    width: 100%;
    perspective: 1000px; 
}

.fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity .25s ease-in-out;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity .25s ease-in-out;
  }