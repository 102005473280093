@media only screen and (max-width: 768px) {
    .descriptionContainer {
        flex-direction: column;
        flex-wrap: wrap;
    }
    .description {
        width: 100% !important;
    }
    .first {
        margin-bottom: 16px;
    }
    .chip {
        min-width: 40% !important;
        align-self: flex-start;
    }
    .socialMedia {
        padding-top: 16px;
        align-self: flex-start;
    }
}

/* Media query para pantallas aún más pequeñas (móviles) */
@media only screen and (max-width: 480px) {
    .descriptionContainer {
        flex-direction: column;
        flex-wrap: wrap;
    }
    .description {
        width: 100% !important;
    }
    .chip {
        min-width: 40% !important;
        align-self: flex-start;
    }
    .socialMedia {
        padding-top: 16px;
        align-self: flex-start;
    }
}