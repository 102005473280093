.shoppingCart {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 1000; 
    background-color: var(--gray-100);
    padding: 16px 16px 10px 10px;
    border: 1px solid #ccc;
    border-radius: 50px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    opacity: 0.65;
    transition: bottom 0.3s ease;
}

.shoppingCart:hover {
    transform: scale(1.1);
    opacity: 1
}