@media only screen and (max-width: 768px) {
    .containerShoppingCartList {
        font-size: small;
    }
}

@media only screen and (max-width: 480px) {
    .containerShoppingCartList {
        font-size: small;
    }
}
