@media only screen and (max-width: 768px) {
    .container {
        min-width: 160px !important;
        min-height: 220px !important;
        max-width: 160px !important;
    }
}

/* Media query para pantallas aún más pequeñas (móviles) */
@media only screen and (max-width: 480px) {
    .container {
        min-width: 160px !important;
        min-height: 220px !important;
        max-width: 160px !important;
    }
}
