  .item {
    transition: background-color 0.3s ease, transform 0.3s ease,
  }

  .item:hover {
    transform: scale(1.05);
  }

  .cartShop {
    transition: background-color 0.3s ease, transform 0.3s ease,
  }

  .cartShop:hover {
    transform: scale(1.1);
  }