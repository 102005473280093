.p-dropdown .p-dropdown-label.p-placeholder {
    display: flex;
}

.p-dropdown {
    background-color: var(--gray-200);
    border: 0; 
}

.p-dropdown-items-wrapper {
    scrollbar-width: none;
    -ms-overflow-style: none; 
}
.p-dropdown-items-wrapper::-webkit-scrollbar {
    display: none;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: var(--gray-800);
}

.p-dropdown-panel {
    background-color: var(--gray-50);
    color: var(--gray-800);
    border-width: 0px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    background-color: var(--gray-100);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus, .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: var(--gray-100);
}

.p-inputtext-sm .p-inputtext .p-component {
    width: 100%;
}

.p-float-label:has(input:focus) label, .p-float-label:has(input.p-filled) label, .p-float-label:has(input:-webkit-autofill) label, .p-float-label:has(textarea:focus) label, .p-float-label:has(textarea.p-filled) label, .p-float-label:has(.p-inputwrapper-focus) label, .p-float-label:has(.p-inputwrapper-filled) label {
    left: 0;
    font-size: 0.8em;
}

.input-div-container {
    padding-top: 8px;
}

.float-label {
    width: 100%;
    font-size: .9em;
    display: flex;
}

.p-inputtext.p-inputtext-sm, .p-filled, .p-inputtext-sm:-webkit-autofill {
    background-color: var(--gray-100) !important;
    color: var(--gray-800) !important;
    border-color: var(--gray-500);
}

.p-inputtext.p-invalid.p-component, .p-dropdown.p-invalid.p-component {
    border: 2px solid #dc5c45;
}

.input:-internal-autofill-selected {
    background-color: var(--gray-100) !important;
}

.saveDirection:hover {
    color: var(--gray-300) !important;
    border-color: var(--gray-100);
    transition: none !important;
}

.goToDirectionAvailable:hover {
    background-color: var(--gray-100);
}

.goToDirectionAvailable:active {
    background-color: var(--gray-200);
}

/* .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 8;
} */