  .scrollBarDirection {
    transition: background-color 0.3s ease, transform 0.3s ease,
  }

  .scrollBarDirection:hover {
    transform: scale(1.1);
  }

  .collection {
    text-decoration: none;
    transition: text-decoration 0.3s ease; 
}

.collection:hover {
    text-decoration: underline;
    text-decoration-color: var(--gray-700);
}